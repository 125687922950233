//////ナビゲーション
$(function () {
  $('.menu_trigger').on('click', function () {
    $(this).toggleClass('active');
    $('.header_nav_contents').slideToggle();
  });
});
$(function () {
  $('.menu_trigger').click(function () {
    $('.header_contents').toggleClass('active');
  });
});
$('.header_nav_contents a').on('click', function () {
  if (window.innerWidth <= 900) {
    $('.menu_trigger').click();
  }
});
$('.directLink').on('click', function () {
  if (window.innerWidth <= 900) {
    $('.menu_trigger').click();
  }
});
$(window).on('resize', function () {
  if ('none' == $('.header_nav_contents').css('display')) {
    $('.header_nav_contents').attr('style', '');
  }
});
//////ナビゲーション(sub pc)
$(function () {
  var width = $(window).width();
  if (width < 900) {
    $(function () {
      $(".nav_inner_sp_ttl").click(function () {
        $(".nav_open").not(this).removeClass("nav_open").next().slideUp(300);
        $(this).toggleClass("nav_open").next().slideToggle(300);
      });
    });
  } else {
    $(function () {
      $(".h_nav_active").hover(function () {
        $('.h_nav_sub_col').toggleClass('active_s');
        $(this).children(".h_nav_sub_item").stop().slideToggle();
      });
    });
    $(function () {
      $(".h_nav_active02").hover(function () {
        $('.h_nav_sub_col02').toggleClass('active_s_02');
        $(this).children(".h_nav_sub_item02").stop().slideToggle();
      });
    });
  }
});
/////ヘッダーサイズ
$(window).scroll(function () {
  if ($(window).scrollTop() > 50) {
    $('.media_header .header_contents').addClass('media_header_animation');
  } else {
    $('.media_header .header_contents').removeClass('media_header_animation');
  }
});

$(function () {
  //表示させるwidthを指定
  // var ua = navigator.userAgent;
  // var viewport = document.querySelector("meta[name=viewport]");
  // if ((ua.indexOf('iPhone') > 0) || ua.indexOf('iPod') > 0 || (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
  //   viewport.setAttribute("content", "width=device-width,initial-scale=1");
  // } else {
  //   viewport.setAttribute("content", "width=1000");
  // }
  //////ページトップ
  var topBtn = $('#page-top');
  topBtn.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 650) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
  topBtn.click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 1000);
    return false;
  });
});

//////スムーススクロール
$(function () {
  $('a[href^="#"]').click(function () {
    var speed = 1500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({
      scrollTop: position
    }, speed, "swing");
    return false;
  });
});
//////スムーススクロールIE
if (navigator.userAgent.match(/(msie|MSIE) 10/i) || navigator.userAgent.match(/(T|t)rident\/7\./) || navigator.userAgent.match(/Edge\/\d+\.\d+/)) {
  $('body').on("mousewheel", function () {
    if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
    }
    var wd = event.wheelDelta;
    var csp = window.pageYOffset;
    window.scrollTo(0, csp - wd);
  });
}
//////リンク
$(document).ready(function () {
  $(".link").click(function () {
    if ($(this).find("a").attr("target") == "_blank") {
      window.open($(this).find("a").attr("href"), '_blank');
    } else {
      window.location = $(this).find("a").attr("href");
    }
    return false;
  });
})
//////telリンク
var ua = navigator.userAgent.toLowerCase();
var isMobile = /iphone/.test(ua) || /android(.+)?mobile/.test(ua);
if (!isMobile) {
  $('a[href^="tel:"]').on('click', function (e) {
    e.preventDefault();
  });
}
//////スクロールアニメーション
$(function () {
  $('.scroll_arrow').click(function () {
    var speed = 1500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({
      scrollTop: position
    }, speed, "swing");
    return false;
  });
});
//////slider
//////recruit_kv_img
$(function () {
  $(".kv_img_area").slick({
    speed: 1000,
    autoplay: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    infinite: true,
    dots: true,
    dotsClass: 'slide_dots',
    arrows: false
  });
});
//////recruit_top_post_contents
$(function () {
  $(".top_post_contents").slick({
    speed: 1000,
    autoplay: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    infinite: true,
    arrows: true,
    prevArrow: '<div class="prev slick-prev"></div>',
    nextArrow: '<div class="next slick-next"></div>',
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    responsive: [{
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        dots: true,
        dotsClass: 'slide_dots'
      }
    }]
  });
});
//////recruit_top_post_contents
$(function () {
  $(".top_benefits_contents").slick({
    speed: 1000,
    autoplay: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    infinite: true,
    arrows: true,
    prevArrow: '<div class="prev slick-prev"></div>',
    nextArrow: '<div class="next slick-next"></div>',
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
      }
    }, {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
      }
    }]
  });
});
//////top_pickup(sp)
$(function () {
  var width = $(window).width();
  if (width < 460) {
    $(".top_pickup_sp").slick({
      speed: 1000,
      autoplay: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      infinite: true,
      arrows: true,
      prevArrow: '<div class="prev slick-prev"></div>',
      nextArrow: '<div class="next slick-next"></div>',
      slidesToScroll: 1,
    });
  }
});
//////tabs
$(function () {
  $('.pages_tab_link li').click(function () {
    var index = $('.pages_tab_link li').index(this);
    $('.pages_tab_link li').removeClass('tab_active');
    $(this).addClass('tab_active');
    $('.tabs_contents').removeClass('show').eq(index).addClass('show');
  });
});
//contact
$(function () {
  if ($('.error')[0]) {
    $('.mw_wp_form').addClass('mw_wp_form_error');
  }
});
//タイピング
$(function () {
  var setElm = $('.split'),
    delaySpeed = 150,
    fadeSpeed = 0;
  setText = setElm.html();
  setElm.css({
    visibility: 'visible'
  }).children().addBack().contents().each(function () {
    var elmThis = $(this);
    if (this.nodeType == 3) {
      var $this = $(this);
      $this.replaceWith($this.text().replace(/(\S)/g, '<span class="textSplitLoad">$&</span>'));
    }
  });
  $(window).load(function () {
    splitLength = $('.textSplitLoad').length;
    setElm.find('.textSplitLoad').each(function (i) {
      splitThis = $(this);
      splitTxt = splitThis.text();
      splitThis.delay(i * (delaySpeed)).css({
        display: 'inline-block',
        opacity: '0'
      }).animate({
        opacity: '1'
      }, fadeSpeed);
    });
    setTimeout(function () {
      setElm.html(setText);
    }, splitLength * delaySpeed + fadeSpeed);
  });
});
/////画像切り替え
$(function () {
  var width = $(window).width();
  if (width < 670) {
    $("img").each(function () {
      $(this).attr("src", $(this).attr("src").replace("_pc", "_sp"));
    })
  }
});

//ダイレクトリンク
$(function () {
  $('.tab_area li').click(function () {
    var index = $('ul.tab_area li').index(this);
    $('.company_contents').css('display', 'none');
    $('.company_contents').eq(index).fadeIn();
    $('.tab_area li').removeClass('tab_active');
    $(this).addClass('tab_active')
  });
});

//ダイレクトリンク companyページのheader
$(function () {
  $('.h_nav_sub_inner .directLink').click(function () {
    var index = $('ul.h_nav_sub_inner .directLink').index(this);
    $('.company_contents').css('display', 'none');
    $('.company_contents').eq(index).fadeIn();
    $('.tab_area li').removeClass('tab_active');
    $('.tab_area li').eq(index).addClass('tab_active');
  });
});

//ダイレクトリンク footer
$(function () {
  $('.f_nav_area .directLink_wrap .directLink').click(function () {
    var index = $('.f_nav_area .directLink_wrap .directLink').index(this);
    $('.company_contents').css('display', 'none');
    $('.company_contents').eq(index).fadeIn();
    $('.tab_area li').removeClass('tab_active');
    $('.tab_area li').eq(index).addClass('tab_active');
    $('html, body').animate({
      scrollTop: 0
    }, 0);
  });
});

$(function () {
  var hash = location.hash;
  hash = (hash.match(/^#tab\d+$/) || [])[0];
  if ($(hash).length) {
    var tabname = hash.slice(1);
  } else {
    var tabname = "tab1";
  }
  $('.company_contents').css('display', 'none');
  $('.tab_area li').removeClass('tab_active');
  var tabno = $('.tab_area li#' + tabname).index();
  $('.company_contents').eq(tabno).fadeIn();
  $('.tab_area li').eq(tabno).addClass('tab_active')
});